<template>
  <div class="login">
    <login-wrap :show-service="true">
      <div class="login-box">
        <!-- 登录方式切换 -->
        <div class="login-tab">
          <!-- <div class="tab" :class="{'active': activeIdx === 0}" @click="changeTab(0)">验证码登陆</div> -->
          <div class="tab" :class="{'active': activeIdx === 1}" @click="changeTab(1)"></div>
        </div>
        <div class="login-form">
          <!-- 手机验证码登录 -->
          <div class="main-login" v-if="activeIdx === 0">
            <el-form status-icon ref="main" :model="main" :rules="mainRules" hide-required-asterisk>
              <el-form-item prop="mobile">
                <el-input
                  v-model.number="main.mobile"
                  placeholder="请输入您的手机号"
                  prefix-icon="el-icon-mobile"
                  @blur="refreshIsCanLogin"
                  @keyup.native="bindInputContentChanged"
                  @keydown.native="unbindInputContentChanged"
                ></el-input>
              </el-form-item>
              <el-form-item class="code" prop="code">
                <el-input
                  v-model.trim="main.code"
                  placeholder="短信验证码"
                  prefix-icon="el-icon-message"
                  @blur="refreshIsCanLogin"
                  @keyup.native="bindInputContentChanged"
                  @keydown.native="unbindInputContentChanged"
                ></el-input>
                <el-button
                  type="primary"
                  class="code-btn"
                  :disabled="!!seconds || !main.mobile"
                  @click="onClickSendSmsButton"
                >{{codeBtn}}</el-button>
              </el-form-item>
            </el-form>
          </div>
          <!-- 子账号登录 -->
          <div class="child-login" v-if="activeIdx === 1">
            <el-form
              :rules="childRules"
              ref="child"
              :model="child"
              status-icon
              hide-required-asterisk
            >
              <el-form-item prop="name">
                <el-input
                  v-model.trim="child.name"
                  placeholder="请输入账号名称"
                  prefix-icon="el-icon-user"
                  @blur="refreshIsCanLogin"
                  @keyup.native="bindInputContentChanged"
                  @keydown.native="unbindInputContentChanged"
                  @change="bindInputContentChanged"
                ></el-input>
              </el-form-item>
              <el-form-item prop="psd">
                <el-input
                  v-model.trim="child.psd"
                  placeholder="登录密码"
                  prefix-icon="el-icon-lock"
                  show-password
                  @blur="refreshIsCanLogin"
                  @keyup.native="bindInputContentChanged"
                  @keydown.native="unbindInputContentChanged"
                  @change="bindInputContentChanged"
                ></el-input>
              </el-form-item>
              <el-form-item prop="code" class="a-mt-10">
               <el-input v-model="child.code"
                 auto-complete="off"
                         @blur="refreshIsCanLogin"
                 prefix-icon="el-icon-picture-outline-round"
                 placeholder="请输入验证码"
                         @keyup.native="bindInputContentChanged"
                         @keydown.native="unbindInputContentChanged"
                         @change="bindInputContentChanged"
                 style="width: 60%">
               </el-input>
                <div class="login-code">
                  <img :src="codeUrl" @click="getSSCode()" class="login-code-img"/>
                </div>
             </el-form-item>
            </el-form>
          </div>
          <div id="gt"></div>
          <div class="loginBtn">
            <el-button class="submit-btn" @click="onClickLoginButton" :disabled="isCanLogin">{{ !isLoading ? '登&nbsp;录' : '登&nbsp;录&nbsp;中' }}<span v-show="isLoading"><i class="dot">•••</i></span></el-button>
          </div>
        </div>
      </div>
    </login-wrap>
  </div>
</template>

<script>
import loginWrap from "./components/login-wrap";
import service from "./api";
import code from "./mixins";
import gt from "../../mixins/geetest";
import {Message} from "element-ui";
var timer;
export default {
  mixins: [code],
  data() {
    return {
      codeUrl: "",
      // 验证码
      activeIdx: 1,
      main: { mobile: "", code: "" },
      child: { psd: "", name: "",code:"" },
      // child: { psd: "lf886520", name: "developer" },
      // 手机号登录校验
      mainRules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: ['blur', 'change'] },
          {
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/,
            message: "请输入正确格式的手机号",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请填写验证码", trigger: ['blur', 'change'] }],
      },
      childRules: {
        name: [
          { required: true, message: "请输入账号名称", trigger: "blur" },
          {
            pattern: /^[a-zA-z0-9]\w{3,15}$/,
            message: "4-16位字符，可含数字、字母、下划线",
            trigger: "blur",
          },
        ],
        psd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[a-zA-z0-9]\w{5,19}$/,
            message: "6-20位字符，可含数字、字母、下划线，不能以下划线开头",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            pattern: /^[0-9]\w{0,5}$/,
            message: "请输入计算的结果",
            trigger: "blur",
          },
        ],
      },
      seconds: 0,
      captcha: false,
      isCanLogin: true,//是否能登陆
      isLoading: false, //登陆中
    };
  },
  components: {
    loginWrap,
  },

  mounted() {
    // this.initGt();
    this.getSSCode();
  },
  methods: {
    initGt() {
      gt.register()
        .then(captcha => {
          this.captcha = captcha;
        })
        .catch((e) => {
          Message.error(e);
        })
    },
    getSSCode() {

      service.getCapture().then(res => {
        // console.log(JSON.stringify(res))
        this.codeUrl = 'data:image/png;base64,'+res.img;
        this.uuid = res.uuid;
      });
    },
    onClickSendSmsButton() {
      this.captcha.onReady(function () {
      }).onSuccess(() => {
        let result = this.captcha.getValidate();
        if (!result)
          return;
        // 登录
        this.getCode('main', result);
      });
      this.captcha.verify();
    },
    onClickLoginButton() {
      if (this.activeIdx === 0) {
        this.login();
      } else {
        // 极验之后登录
        // this.captcha.onReady(function () {
        // }).onSuccess(() => {
        //   let result = this.captcha.getValidate();
        //   if (!result)
        //     return;
          // 登录
          this.login({ ...this.child, uuid: this.uuid, code: this.child.code});
        // });
        // this.captcha.verify();
      }
    },
    changeTab(type) {
      let resetForms = ["child", "main"];
      this.activeIdx = type;
      this.$refs[resetForms[type]].resetFields();
      this.isCanLogin = true;
      this.initGt();
    },
    login(extData = {}) {
      clearTimeout(this.loginTimer);
      if(!this.isLoading) {
        this.isLoading = true;
        this.isCanLogin = true;
        if (this.activeIdx === 0) {
          this.loginTimer = setTimeout(() =>this.loginBySms(extData), 500)
        } else {
          this.loginTimer = setTimeout(() => this.loginByPassword(extData), 500)
        }
      }
    },
    // 登录
    loginBySms(extData = {}) {
      let check = false;
      this.$refs["main"].validate((res) => (check = res));
      if (!check) return;
      return this.processLogin(service.toLogin({...this.main}));
    },
    // 子账号登录
    loginByPassword(extData = {}) {
      let check = false;
      this.$refs["child"].validate((res) => (check = res));
      if (!check) return;
      let { name = "", psd = "",code = "" } = this.child;
      return this.processLogin(
        service.childLogin({ account: name, password: psd,uuid:this.uuid,code: code, ...extData })
      );
    },
    processLogin(r) {
      return r.then((res) => {
        this.isLoading = false;
        sessionStorage.setItem("xinyou_sh_user", JSON.stringify(res));
        if (res.hasOwnProperty("token")) {
          sessionStorage.setItem("token", res.token);
        }
        let { merchantName = "商户" } = res;
        sessionStorage.setItem("merchant_name", merchantName);
        // if (!location.hostname.endsWith('fanglu6.com') && location.hostname !== 'localhost')
        //   sessionStorage.setItem("tips", true);

            if (res.amount / 100 < 100 ){
                if(res.roleId === 100){
                  sessionStorage.setItem("baranceTips", false);
                  sessionStorage.setItem("noticeTips", true);
                }else{
                  sessionStorage.setItem("baranceTips", true);
                  sessionStorage.setItem("noticeTips", false);
                }
            }else{
              sessionStorage.setItem("baranceTips", false);
              sessionStorage.setItem("noticeTips", true);
            }

        this.$router.push({ path: "/control" });
      }).catch(() => {
        this.isLoading = false;
        this.initGt()
        this.refreshIsCanLogin();
      });
    },
    //是否能登陆
    refreshIsCanLogin(){
      let check = false;
      if (this.activeIdx === 0){
        this.$refs["main"].validate((res) => (check = res));
      }else{
        this.$refs["child"].validate((res) => (check = res));
      };

      this.isCanLogin = !check;
      clearTimeout(timer);
    },
    bindInputContentChanged () {
      let self = this;
      timer = setTimeout(function(){
        self.refreshIsCanLogin();
      }, 300);
    },
    unbindInputContentChanged() {
      clearTimeout(timer);// 取消定时器
    },
    isWeChat(){
      let ua = window.navigator.userAgent.toLowerCase()
      if(ua.match(/MicroMessenger/i) == 'micromessenger'){
        let system = {
              win: false,
              mac: false
          };
          //检测平台
          var p = navigator.platform;
          system.win = p.indexOf("Win") == 0;
        system.mac = p.indexOf("Mac") == 0;
        // console.log(system.win || system.mac)
        if (system.win || system.mac) {
            // alert("在微信PC端上打开内置浏览器");
            this.$router.push({path: '/tips'})
        }else {
              // alert("非微信PC端上打开内置浏览器");
        }
      }

    },
    beforeDestroy(){
      clearTimeout(this.loginTimer)
    }
  },
  created(){
    this.isWeChat()
    this.$nextTick(()=>{
      if(this.$refs.main){
        this.$refs.main.clearValidate();
      }
    })
  }
}
</script>
<style lang="scss" scoped>

.wrap-body{
  background-image: url('../../assets/h5img/img_1.png');
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 3rem;
}

.login-code {
  width: 30%;
  // height: 38px;
  float: right;
  img {
    width: 100%;
    cursor: pointer;
    vertical-align: middle;
  }
}
.login-form{
  padding-top: 2rem;
}

.login {

  // background-color: #776BCC;
  background-image: url('../../assets/bj.jpg');
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  &-tab {
    width: 320px;
    margin: 0 auto;

    // margin-top: 130px;
    display: flex;
    .tab {
      margin-right: 30px;
      cursor: pointer;
      font-size: 24px;
      margin-bottom: 30px;
      &.active {
        font-weight: 600;
      }
    }
  }
  &-form {
    width: 320px;
    margin: 0 auto;
    // margin-top: 30px;
    .el-input {
      width: 320px;
      font-size: 16px;
    }
  }
  .code {
    .el-input {
      width: 200px;
      margin-right: 5px;
    }
    &-btn {
      width: 112px;
    }
  }
  .loginBtn{
    .submit-btn.el-button {
      width: 100%;
      margin-top: 20px;
      font-size: 18px;
      color:#ffffff;
      background-color: #007bff;
      border-color: #007bff;
    }
    .el-button.is-disabled{
      background-color: #e2e2e2;
      border-color: #e2e2e2;
      &:hover{
        background-color: #e2e2e2;
        border-color: #e2e2e2;
      }
    }
    span{
      padding:0;
      display: inline-block;
      width:1.5rem;
      .dot{
        display: inline-block;
        font-size:14px;
        letter-spacing:2px;
        //width: 2em;
        vertical-align: bottom;
        overflow: hidden;
        animation: dot 1s infinite step-start;
      }
      @keyframes dot {
        0% { width: 0; margin-right: 1.5em; }
        33% { width: .5em; margin-right: 1em; }
        66% { width: 1em; margin-right: .5em; }
        100% { width: 1.5em; margin-right: 0;}
      }
    }

  }
}
@media screen and (max-width: 480px) {

.login{
  // width: 85%;
  padding: 0 8%;
  .wrap-body{
    padding: 50px 30px 30px;
    width: 100%;
     .login-form{
         margin-top: 0.5rem;

        width: 100%;
        .el-input{
          width: 100%;
        }
        .el-form-item{
          margin-bottom: 1rem;
          line-height: 1.25rem;

        }

      }
    &-form {
      width: 100%;
      margin-top: 0.5rem;
      .el-input {
        width: 50%;
        font-size: 10px;
      }
    }
    .code {
      .el-input {
        width: 65%;
        float: left;
      }
      &-btn {
        width: 30%;
        font-size: 8px;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

}
@media screen and (max-width: 480px) {

.geetest_holder.geetest_wind{
  min-width: 100%!important;
}
.loginBtn{
    .submit-btn.el-button {
      width: 100%;
      margin-bottom: 2rem;
    }
}
}
}
</style>
